<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M39.1505 8.82254C38.0585 7.73054 36.2847 7.72585 35.187 8.80841L21.2149 22.0214C18.789 21.5476 16.1773 22.2482 14.3021 24.1235C12.0593 26.3662 11.9056 28.7546 11.757 31.0643C11.6175 33.2331 11.4732 35.4757 9.544 37.9887L8 40H10.5356C14.2319 40 17.2567 39.5193 19.7827 38.5303C21.8374 37.7259 23.5467 36.5989 25.162 34.9835C27.0184 33.1272 27.7234 30.5491 27.2778 28.1443L39.2133 12.7353C40.2467 11.6342 40.2257 9.89785 39.1505 8.82254ZM23.3942 33.2156C22.0406 34.5693 20.2517 35.942 17.1657 36.7569C18.9518 35.389 20.6484 33.5221 21.5268 31.0419L19.1702 30.2072C18.13 33.1441 15.5111 35.0727 13.3698 36.2126C14.0487 34.3765 14.1579 32.6871 14.252 31.2248C14.3871 29.1256 14.4937 27.4675 16.0699 25.8913C17.0797 24.8815 18.4056 24.3768 19.732 24.3768C21.0581 24.3768 22.3846 24.8817 23.3942 25.8913C25.4135 27.9106 25.4135 31.1963 23.3942 33.2156ZM26.2139 25.4351C25.9182 24.9698 25.5679 24.5292 25.162 24.1235C24.7376 23.699 24.2753 23.3348 23.786 23.0307L25.819 21.1081L28.0644 23.046L26.2139 25.4351ZM37.3826 11.0324L37.3267 11.0883L29.5975 21.0668L27.645 19.3816L36.9157 10.6147L36.9407 10.5904C37.0625 10.4686 37.2608 10.4686 37.3826 10.5904C37.5045 10.7123 37.5045 10.9105 37.3826 11.0324Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-brush-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
